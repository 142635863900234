import { useCache } from './useCache';
import { API_KEYS } from './constants';
import { failure, success, type Response, isSuccess } from './response';
import { DEV_URL, DOMAIN, devOrProd } from '../util/env';
import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from './useApi';
import {
  type ListPlaylistInput,
  type ListPlaylistOutput,
  type CreatePlaylistInput,
  type DeletePlaylistInput,
  type GetSongInput,
  type GetSongOutput,
  type ListSongsOutput,
  type CreateSongInput,
  type DeleteSongInput,
  type CreatePlaylistOutput,
  type AuthenticateInput,
  type AuthenticateOutput,
  type RefreshAuthInput,
  type ListRecordsInput,
  type CreatePhotoInput,
  type ListPhotosOutput,
  type ListUnescoSitesOutput,
  type Location,
  type ListLocationsOutput,
  type DeletePhotoInput,
} from '../util/types';
import { useAxios } from './useAxios';

export const useListYoutubePlaylistItems = (
  playlistId: string,
): Response<any, Error> => {
  const loadAll = async (): Promise<any[]> => {
    const items = [];

    let request = async (): Promise<any> =>
      await apiGet(`/youtube/playlistItems/${playlistId}`);

    let nextPageToken: string | undefined;
    do {
      const response = await request();
      items.push(...response.data.items);
      nextPageToken = response.data.nextPageToken;

      request = async () =>
        await apiGet(
          `/youtube/playlistItems/${playlistId}`,
          `nextPageToken=${nextPageToken}`,
        );
    } while (nextPageToken);
    return items;
  };
  return useCache(loadAll, [API_KEYS.getYoutubePlaylist, playlistId]);
};

export const useAuthenticateCognito = ({
  code,
}: AuthenticateInput): Response<AuthenticateOutput, Error> => {
  const request = {
    code,
    redirectUri: devOrProd(
      `http://${DEV_URL}/authenticate`,
      `https://${DOMAIN}/authenticate`,
    ),
  };

  const fetch = async (): Promise<any> => await apiPost('/auth', request);
  return useCache(fetch, [API_KEYS.authenticateCognito, request.code]);
};

export const useRefreshCognito = (): any => {
  const refresh = async ({
    refreshToken,
  }: RefreshAuthInput): Promise<Response<RefreshAuthInput, Error>> => {
    const request = {
      refreshToken,
      redirectUri: devOrProd(
        `http://${DEV_URL}/authenticate`,
        `https://${DOMAIN}/authenticate`,
      ),
    };
    try {
      const resp = await apiPost('/auth/refresh', request);
      return success(resp.data as RefreshAuthInput);
    } catch (e) {
      return failure(new Error(JSON.stringify(e)));
    }
  };

  return { refresh };
};

export const useListUserPlaylists = ({
  userId,
}: ListPlaylistInput): Response<ListPlaylistOutput, Error> => {
  const fetch = async (): Promise<any> =>
    await apiGet<ListPlaylistOutput>(`/playlist/${userId}`);

  return useCache(fetch, [API_KEYS.listUserPlaylists, userId]);
};

export const useCreateUserPlaylist = ({
  userId,
  playlistId,
}: CreatePlaylistInput): any => {
  const createUserPlaylist = async (): Promise<
    Response<CreatePlaylistOutput, Error>
  > => {
    const body = {
      playlistId,
    };

    try {
      const resp = await apiPost(`/playlist/${userId}`, body);
      return success({
        playlistName: (resp.data as CreatePlaylistOutput).playlistName,
      });
    } catch (e) {
      return failure(new Error(JSON.stringify(e)));
    }
  };

  return {
    createUserPlaylist,
  };
};

export const useDeleteUserPlaylist = ({
  userId,
}: Partial<DeletePlaylistInput>): any => {
  const deleteUserPlaylist = async (
    playlistId: string,
  ): Promise<Response<null, Error>> => {
    if (!userId) {
      return failure(new Error());
    }

    try {
      await apiDelete(`/playlist/${userId}/${playlistId}`);
      return success(null);
    } catch (e) {
      return failure(new Error(JSON.stringify(e)));
    }
  };

  return {
    deleteUserPlaylist,
  };
};

export const useListSongs = (): Response<ListSongsOutput, Error> => {
  const fetch = async (): Promise<any> =>
    await apiGet<ListSongsOutput>('/song');

  return useCache(fetch, [API_KEYS.listSongs]);
};

export const useGetSong = ({
  songId,
}: GetSongInput): Response<GetSongOutput, Error> => {
  const fetch = async (): Promise<any> =>
    await apiGet<GetSongOutput>(`/song/${songId}`);

  return useCache(fetch, [API_KEYS.getSong, songId]);
};

export const useCreateSong = ({
  artistName,
  songName,
  shortDescription,
  longDescription,
  image,
  genres,
  genreCategory,
}: CreateSongInput): any => {
  const createSong = async (): Promise<Response<null, Error>> => {
    const body = {
      artistName,
      songName,
      shortDescription,
      longDescription,
      image,
      genres,
      genreCategory,
    };

    try {
      await apiPost('/song', body);
      return success(null);
    } catch (e) {
      return failure(new Error(JSON.stringify(e)));
    }
  };

  return {
    createSong,
  };
};

export const useDeleteSong = ({ songId }: DeleteSongInput): any => {
  const deleteSongEntry = async (): Promise<Response<null, Error>> => {
    try {
      await apiDelete(`/song/${songId}`);
      return success(null);
    } catch (e) {
      return failure(new Error(JSON.stringify(e)));
    }
  };

  return {
    deleteSongEntry,
  };
};

export const useListRecords = ({
  username,
}: ListRecordsInput): Response<any, Error> => {
  const loadAll = async (): Promise<any[]> => {
    const items = [];

    let request = async (): Promise<any> =>
      await apiGet(`/records/${username}`);

    let currPage = 0;
    do {
      currPage += 1;
      const response = await request();
      items.push(...response.data.releases);
      const maxPages = response.data.pagination.pages;

      if (currPage === maxPages) {
        break;
      }

      request = async () =>
        await apiGet(`/records/${username}`, `page=${currPage}`);
    } while (currPage);
    return items;
  };
  return useCache(loadAll, [API_KEYS.listRecords, username]);
};

export const useListPhotos = (): Response<ListPhotosOutput, Error> => {
  const fetch = async (): Promise<any> =>
    await apiGet<ListPhotosOutput>('/photography');

  return useCache(fetch, [API_KEYS.listPhotos]);
};

export const useCreatePhoto = (input: CreatePhotoInput): any => {
  const createPhoto = async (): Promise<Response<null, Error>> => {
    const body = {
      ...input,
    };

    try {
      await apiPost('/photography', body);
      return success(null);
    } catch (e) {
      return failure(new Error(JSON.stringify(e)));
    }
  };

  return {
    createPhoto,
  };
};

export const useListUnescoSites = (): Response<
  ListUnescoSitesOutput,
  Error
> => {
  const fetch = async (): Promise<any> =>
    await apiGet<ListUnescoSitesOutput>('/unesco');

  return useCache(fetch, [API_KEYS.listSites]);
};

export const useListVisitedUnescoSites = (): Response<
  ListUnescoSitesOutput,
  Error
> => {
  const fetch = async (): Promise<any> =>
    await apiGet<ListUnescoSitesOutput>('/unesco/visited');

  return useCache(fetch, [API_KEYS.listVisitedSites]);
};

export const useUpdateSiteVisited = (): any => {
  const updateSiteVisited = async (
    siteName: string,
    subSiteName: string,
    visited: boolean,
  ): Promise<Response<null, Error>> => {
    const body = {
      siteName,
      subSiteName,
      visited,
    };

    try {
      await apiPatch('/unesco', body);
      return success(null);
    } catch (e) {
      console.log(e);
      return failure(new Error(JSON.stringify(e)));
    }
  };
  return {
    updateSiteVisited,
  };
};

export const useListLocations = (): Response<ListLocationsOutput, Error> => {
  const fetch = async (): Promise<any> =>
    await apiGet<ListLocationsOutput>('/location');

  return useCache(fetch, [API_KEYS.listLocations]);
};

export const useCreateLocation = (): any => {
  const createLocation = async (
    location: Location,
  ): Promise<Response<null, Error>> => {
    const body = {
      ...location,
    };

    try {
      await apiPut('/location', body);
      return success(null);
    } catch (e) {
      console.log(e);
      return failure(new Error(JSON.stringify(e)));
    }
  };
  return {
    createLocation,
  };
};

export const useGetPresignedUploadUrl = (): any => {
  const getPresignedUrl = async (
    photoId: string,
  ): Promise<Response<any, Error>> => {
    const body = {
      photoId,
    };

    try {
      const resp = await apiPost('/auth/s3/upload', body);
      return success(resp.data);
    } catch (e) {
      return failure(new Error(JSON.stringify(e)));
    }
  };

  return {
    getPresignedUrl,
  };
};

export const useUploadImageToS3 = (): any => {
  const { getPresignedUrl } = useGetPresignedUploadUrl();
  const { put } = useAxios();
  const uploadImageToS3 = async (
    photoId: string,
    file: File,
  ): Promise<Response<any, Error>> => {
    const presignedResp = await getPresignedUrl(photoId);
    console.log(file);
    if (isSuccess(presignedResp)) {
      const url = presignedResp.data;
      try {
        await put(url, file, { 'Content-Type': 'image/*' });
        return success(null);
      } catch (e) {
        return failure(new Error(JSON.stringify(e)));
      }
    }
  };

  return {
    uploadImageToS3,
  };
};

export const useDeletePhoto = (): any => {
  const deletePhoto = async ({
    photoId,
  }: DeletePhotoInput): Promise<Response<null, Error>> => {
    try {
      await apiDelete(`/photography/${photoId}`);
      return success(null);
    } catch (e) {
      return failure(new Error(JSON.stringify(e)));
    }
  };
  return {
    deletePhoto,
  };
};
