import React, { useEffect } from 'react';
import { Select, Spinner } from '@chakra-ui/react';
import { isSuccess } from '../../../service/response';
import { type Location } from '../../../util/types';
import { useListLocations } from './useListLocations';

interface LocationSelectProps {
  selectedLocation: Location | undefined;
  setSelectedLocation: React.Dispatch<
    React.SetStateAction<Location | undefined>
  >;
}

export const LocationSelect = ({
  selectedLocation,
  setSelectedLocation,
}: LocationSelectProps): JSX.Element => {
  const locations = useListLocations();

  useEffect(() => {
    if (!selectedLocation && isSuccess(locations)) {
      setSelectedLocation(locations.data[0]);
    }
  }, [locations]);

  return isSuccess(locations) ? (
    <>
      <Select
        value={selectedLocation?.locationName}
        onChange={(e) => {
          setSelectedLocation(
            locations.data.find(
              (location: Location) => location.locationName === e.target.value,
            ),
          );
        }}
      >
        {locations.data.map((site: Location) => {
          return (
            <option key={site.locationName} value={site.locationName}>
              {site.locationName}
            </option>
          );
        })}
      </Select>
    </>
  ) : (
    <Spinner />
  );
};
