import React, { useContext, useState } from 'react';

import { UserContext } from '../app/App';
import { Button, Input } from '@chakra-ui/react';
import { useCreateLocation } from './useCreateLocation';

export const Location = (): JSX.Element => {
  const { user } = useContext(UserContext);

  const [locationName, setLocationName] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');

  const { createLocation } = useCreateLocation({
    locationName,
    country,
    latitude,
    longitude,
  });
  return user ? (
    <>
      <Input
        placeholder="Location name"
        value={locationName}
        onChange={(e) => {
          setLocationName(e.target.value);
        }}
      />
      <Input
        placeholder="Country"
        value={country}
        onChange={(e) => {
          setCountry(e.target.value);
        }}
      />
      <Input
        placeholder="Latitude"
        value={latitude}
        onChange={(e) => {
          setLatitude(e.target.value);
        }}
      />
      <Input
        placeholder="Longitude"
        value={longitude}
        onChange={(e) => {
          setLongitude(e.target.value);
        }}
      />
      <Button onClick={createLocation}>Submit</Button>
    </>
  ) : (
    <></>
  );
};
