import { useCreateLocation as useCreateLocationRequest } from '../../service/requests';
import {
  isFailure,
  failure,
  isSuccess,
  success,
  loading,
  type Response,
} from '../../service/response';
import { type Location } from '../../util/types';

interface Input {
  locationName: string;
  country: string;
  latitude: string;
  longitude: string;
}

export const useCreateLocation = ({
  locationName,
  country,
  latitude,
  longitude,
}: Input): any => {
  const { createLocation: createLocationRequest } = useCreateLocationRequest();
  const createLocation = async (): Promise<Response<any, Error>> => {
    const location: Location = {
      locationName,
      country,
      latitude: Number(latitude),
      longitude: Number(longitude),
    };

    const resp = await createLocationRequest(location);

    if (isFailure(resp)) {
      return failure(resp.error as Error);
    }

    if (isSuccess(resp)) {
      return success(null);
    }

    return loading();
  };

  return {
    createLocation,
  };
};
