import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  HStack,
  IconButton,
  Input,
  Link,
  Radio,
  RadioGroup,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { StateView } from '../../common/StateView';
import { useGetYoutubePlaylist } from './useGetYoutubePlaylist';
import { EndState, shuffle, type Video, PlayerState } from './config';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbGroup } from '../../common/breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPause,
  faPlay,
  faTimes,
  faVolumeMute,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';
import { type ReactInputEvent } from '../../../util/types';
import ReactPlayer from 'react-player/youtube';

const DEFAULT_VOLUME = 50;
const VOLUME_LOCAL_STORAGE_KEY = 'randomizerVolume';

export const Player: React.FC = StateView(useGetYoutubePlaylist, (data) => {
  let { videos } = data;

  const [queueIndex, setQueueIndex] = useState<number>(0);
  const [displayPlayer, setDisplayPlayer] = useState<boolean>(true);
  const [showList, setShowList] = useState<boolean>(true);
  const [playerState, setPlayerState] = useState<PlayerState>(
    PlayerState.Loading,
  );
  const [endState, setEndState] = useState<EndState>(EndState.Restart);
  const [searchValue, setSearchValue] = useState<string>('');
  const [displayUnplayable, setDisplayUnplayable] = useState<boolean>(false);
  const [unplayableVideos, setUnplayableVideos] = useState<Video[]>([]);
  const [volume, setVolume] = useState<number>(
    Number(localStorage.getItem(VOLUME_LOCAL_STORAGE_KEY) ?? DEFAULT_VOLUME),
  );
  const [isMuted, setIsMuted] = useState<boolean>(false);

  const addUnplayedVideo = (video: Video): void => {
    if (!unplayableVideos.includes(video)) {
      setUnplayableVideos((prevState) => [...prevState, video]);
    }
  };

  const currSongRef = useRef<HTMLTableCellElement>(null);

  const currentVideo = videos[queueIndex];

  useEffect(() => {
    if (playerState === PlayerState.Error) {
      addUnplayedVideo(currentVideo);
      currSongRef.current?.scrollIntoView();
      nextVideo();
    }
  }, [playerState]);

  const previousVideo = (): void => {
    setQueueIndex((currValue) => nextPlayableVideo(currValue - 1, -1));
  };

  const nextVideo = (): void => {
    if (isLastSong) {
      switch (endState) {
        case EndState.Restart:
          setQueueIndex(0);
          return;
        case EndState.ShuffleAndRestart:
          videos = videos.sort(shuffle);
          setQueueIndex(0);
          return;
        case EndState.End:
          return;
      }
    }
    setQueueIndex((currValue) => nextPlayableVideo(currValue + 1, 1));
    currSongRef.current?.scrollIntoView();
  };

  const nextPlayableVideo = (index: number, direction: number): number => {
    while (true) {
      if (videos[index].playable) {
        return index;
      }
      addUnplayedVideo(videos[index]);
      index += direction;
    }
  };

  const handlePlayClick = (): void => {
    setPlayerState(isPlaying ? PlayerState.Paused : PlayerState.Playing);
  };

  const isPaused = playerState === PlayerState.Paused;
  const isPlaying = playerState === PlayerState.Playing;
  const isLoading = playerState === PlayerState.Loading;
  const isUnplayable = playerState === PlayerState.Error;

  const isLastSong = queueIndex === videos.length - 1;

  const playButtonIcon = isPlaying ? (
    <FontAwesomeIcon icon={faPause} />
  ) : isPaused ? (
    <FontAwesomeIcon icon={faPlay} />
  ) : isLoading ? (
    <Spinner />
  ) : (
    <FontAwesomeIcon icon={faTimes} />
  );

  const muteButtonIcon = !isMuted ? (
    <FontAwesomeIcon icon={faVolumeUp} />
  ) : (
    <FontAwesomeIcon icon={faVolumeMute} />
  );

  const opts = {
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  };

  return (
    <Box>
      <Helmet defer={false}>
        <title>{currentVideo.title}</title>
      </Helmet>
      <BreadcrumbGroup
        breadcrumbPaths={['home', 'youtubeRandomizerHome', 'youtubeRandomizer']}
      />
      <Center>
        <HStack spacing={20}>
          <VStack>
            <Box visibility={displayPlayer ? 'visible' : 'hidden'}>
              {currentVideo.id && currentVideo.title && (
                <ReactPlayer
                  url={`https://youtube.com/watch?v=${currentVideo.id}`}
                  playing={playerState === PlayerState.Playing}
                  onPause={() => {
                    setPlayerState(PlayerState.Paused);
                  }}
                  onReady={() => {
                    setPlayerState(PlayerState.Playing);
                  }}
                  onBuffer={() => {
                    setPlayerState(PlayerState.Loading);
                  }}
                  onBufferEnd={() => {
                    setPlayerState(PlayerState.Playing);
                  }}
                  onError={() => {
                    setPlayerState(PlayerState.Error);
                  }}
                  onEnded={nextVideo}
                  config={opts}
                  volume={volume / 100}
                  muted={isMuted}
                />
              )}
            </Box>
            <Container>
              <Text>{currentVideo.title}</Text>
              <Button
                disabled={queueIndex === 0 || isUnplayable}
                onClick={previousVideo}
              >
                Previous
              </Button>
              <IconButton
                disabled={(!isPaused && !isPlaying) || isUnplayable}
                onClick={handlePlayClick}
                aria-label="Play"
                icon={playButtonIcon}
              />
              <Button
                disabled={
                  (isLastSong && endState === EndState.End) || isUnplayable
                }
                onClick={nextVideo}
              >
                Next
              </Button>
            </Container>
            <Container>
              <Slider
                maxW="200px"
                value={volume}
                onChange={(value: number) => {
                  setVolume(value);
                }}
                onChangeEnd={() => {
                  localStorage.setItem(
                    VOLUME_LOCAL_STORAGE_KEY,
                    volume.toString(),
                  );
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
              <IconButton
                onClick={() => {
                  setIsMuted((prevState) => !prevState);
                }}
                variant="ghost"
                aria-label="mute"
                icon={muteButtonIcon}
              />
            </Container>
            <HStack spacing={4}>
              <Checkbox
                isChecked={displayPlayer}
                onChange={(e: ReactInputEvent) => {
                  setDisplayPlayer(e.target.checked);
                }}
              >
                Show player
              </Checkbox>
              <Checkbox
                isChecked={showList}
                onChange={(e: ReactInputEvent) => {
                  setShowList(e.target.checked);
                }}
              >
                Show list
              </Checkbox>
            </HStack>
            <RadioGroup
              onChange={(e: EndState) => {
                setEndState(e);
              }}
              value={endState}
            >
              <Text>Playlist End Options</Text>
              <Stack direction="column">
                <Radio value={EndState.Restart}>Restart</Radio>
                <Radio value={EndState.ShuffleAndRestart}>
                  Shuffle and Restart
                </Radio>
                <Radio value={EndState.End}>End</Radio>
              </Stack>
            </RadioGroup>
          </VStack>
          <VStack visibility={showList ? 'visible' : 'hidden'}>
            <Input
              size="sm"
              placeholder="Search"
              value={searchValue}
              onChange={(e: ReactInputEvent) => {
                setSearchValue(e.target.value);
              }}
            />
            <VStack h="450px" w="350px" overflowY="scroll" overflowX="hidden">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Video</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {videos
                    .map((video, index) => {
                      return (
                        <Tr
                          style={{
                            display: !video.playable ? 'none' : 'block',
                          }}
                          key={video.id}
                          name={video.title}
                        >
                          <Td
                            ref={index === queueIndex ? currSongRef : null}
                            fontWeight={
                              index === queueIndex ? 'bold' : 'normal'
                            }
                          >
                            <Link
                              onClick={() => {
                                setQueueIndex(index);
                              }}
                            >
                              {video.title}
                            </Link>
                          </Td>
                        </Tr>
                      );
                    })
                    .filter(
                      (video) =>
                        video.props.name
                          ?.toLowerCase()
                          .includes(searchValue.toLowerCase()),
                    )}
                </Tbody>
              </Table>
            </VStack>
            <VStack>
              <Link
                onClick={() => {
                  setDisplayUnplayable(true);
                }}
                style={{ display: displayUnplayable ? 'none' : 'block' }}
              >
                Show unplayable videos
              </Link>
              <Table
                style={{ display: !displayUnplayable ? 'none' : 'block' }}
                size="sm"
              >
                <Thead>
                  <Tr>
                    <Th>Unplayable videos</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {unplayableVideos.map((video) => {
                    return (
                      <Tr key={video.id} name={video.title}>
                        <Td>
                          <Text>{video.title}</Text>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </VStack>
          </VStack>
        </HStack>
      </Center>
    </Box>
  );
});
