import { isSuccess, success, type Response } from '../../../service/response';
import { type UnescoSite } from '../../../util/types';
import { useListVisitedUnescoSites } from '../../../service/requests';

export const useListVisitedSites = (): Response<UnescoSite[], Error> => {
  const resp = useListVisitedUnescoSites();

  if (isSuccess(resp)) {
    return success(resp.data);
  }

  return resp;
};
