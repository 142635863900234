import _ from 'lodash';

export interface Playlist {
  videos: Video[];
}

export interface Video {
  id: string | undefined | null;
  title: string | null | undefined;
  playable: boolean;
}

export enum PlayerState {
  Loading = 'loading',
  Playing = 'playing',
  Paused = 'paused',
  Error = 'error',
}

export enum EndState {
  Restart = 'restart',
  ShuffleAndRestart = 'shuffleAndRestart',
  End = 'end',
}

export const shuffle = (): number => 0.5 - Math.random();

export const toPlaylist = (youtubePlaylist: any): Playlist => ({
  videos: _.uniqBy(_.map(youtubePlaylist, toVideo).sort(shuffle), 'id'),
});

export const toVideo = (item: any): Video => ({
  id: item.snippet.resourceId.videoId,
  title: item.snippet.title,
  playable: Object.keys(item.snippet.thumbnails).length > 0,
});
