import { useNavigate } from 'react-router';
import { useCreatePhoto, useUploadImageToS3 } from '../../../service/requests';
import {
  type Response,
  isFailure,
  isSuccess,
  loading,
} from '../../../service/response';
import { toUrl, createRoute } from '../../../util/routes';
import { type UnescoSite, type Location } from '../../../util/types';
import { v4 as uuid } from 'uuid';
interface UploadPhotoProps {
  file: File;
  siteMetadata: UnescoSite | Location;
}

export const useUploadPhoto = ({
  file,
  siteMetadata,
}: UploadPhotoProps): any => {
  const navigate = useNavigate();
  const { uploadImageToS3 } = useUploadImageToS3();

  const uploadPhoto = async (): Promise<Response<any, any>> => {
    const photoId = uuid();

    const uploadResp = await uploadImageToS3(photoId, file);

    if (isSuccess(uploadResp)) {
      const { createPhoto } = useCreatePhoto({
        ...siteMetadata,
        photoId,
      });

      const resp = await createPhoto();

      if (!isFailure(resp) && !isSuccess(resp)) {
        return loading();
      }

      if (isSuccess(resp)) {
        navigate(toUrl(createRoute('photography', {})));
      }

      return resp;
    }

    if (isFailure(uploadResp)) {
      return uploadResp;
    }
  };
  return {
    uploadPhoto,
  };
};
