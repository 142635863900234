export const devOrProd = <D, P>(dev: D, prod: P): D | P => {
  return process.env.NODE_ENV === 'production' ? prod : dev;
};

export const DOMAIN = 'caseysmith.ca';
export const DEV_URL = 'localhost:3333';

export const GOOGLE_MAPS_DEV_API_KEY =
  'AIzaSyDJjm_tIqiZdsYt2xDunRMkXgMdxH4ikEI';
export const GOOGLE_MAPS_PROD_API_KEY =
  'AIzaSyD2qCfbYPgkHYU4XLu-VgHufZidtliDuec';
