import { useListPhotos as listPhotos } from '../../service/requests';
import { isSuccess, success, type Response } from '../../service/response';
import { type Photo } from '../../util/types';

interface Result {
  photoResult: Photo[];
}

export const useListPhotos = (): Response<Result, Error> => {
  const resp = listPhotos();

  if (isSuccess(resp)) {
    return success({ photoResult: resp.data });
  }

  return resp;
};
