import React, { useEffect, useMemo, useState } from 'react';
import { Select, Spinner } from '@chakra-ui/react';
import { isSuccess } from '../../../service/response';
import { useListVisitedSites } from './useListVisitedSites';
import { type UnescoSite } from '../../../util/types';

interface UnescoSitesSelectProps {
  setSelectedUnescoSite: React.Dispatch<
    React.SetStateAction<UnescoSite | undefined>
  >;
}

export const UnescoSitesSelect = ({
  setSelectedUnescoSite,
}: UnescoSitesSelectProps): JSX.Element => {
  const sites = useListVisitedSites();
  const [selectedUnescoSiteName, setSelectedUnescoSiteName] =
    useState<string>();
  const [selectedUnescoSubSiteName, setSelectedUnescoSubSiteName] =
    useState<string>();

  useEffect(() => {
    if (!selectedUnescoSiteName && isSuccess(sites)) {
      setSelectedUnescoSiteName(sites.data[0].siteName);
    }
  }, [sites]);

  useEffect(() => {
    if (isSuccess(sites)) {
      setSelectedUnescoSite(
        sites.data.find(
          (site: UnescoSite) =>
            site.siteName === selectedUnescoSiteName &&
            site.subSiteName === selectedUnescoSubSiteName,
        ),
      );
    }
  }, [selectedUnescoSubSiteName]);

  useEffect(() => {
    if (isSuccess(sites) && selectedUnescoSiteName) {
      setSelectedUnescoSubSiteName(
        sites.data.filter(
          (site: UnescoSite) => site.siteName === selectedUnescoSiteName,
        )[0].subSiteName,
      );
    }
  }, [selectedUnescoSiteName]);

  const uniqueSites = useMemo(() => {
    if (isSuccess(sites)) {
      return Array.from(
        new Set(sites.data.map((site: UnescoSite) => site.siteName)),
      ).sort();
    }
  }, [sites]);

  return isSuccess(sites) ? (
    <>
      <Select
        value={selectedUnescoSiteName}
        onChange={(e) => {
          setSelectedUnescoSiteName(e.target.value);
        }}
      >
        {uniqueSites?.map((site: string) => {
          return (
            <option key={site} value={site}>
              {site}
            </option>
          );
        })}
      </Select>
      {selectedUnescoSiteName && (
        <Select
          value={selectedUnescoSubSiteName}
          onChange={(e) => {
            setSelectedUnescoSubSiteName(e.target.value);
          }}
        >
          {sites.data
            .filter(
              (site: UnescoSite) => site.siteName === selectedUnescoSiteName,
            )
            .map((site: UnescoSite) => {
              return (
                <option key={site.subSiteName} value={site.subSiteName}>
                  {site.subSiteName}
                </option>
              );
            })}
        </Select>
      )}
    </>
  ) : (
    <Spinner />
  );
};
