import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import { ImageUpload } from '../../common/imageUpload';

import { useUploadPhoto } from './useUploadPhoto';
import { useNavigate } from 'react-router';
import { toUrl, createRoute } from '../../../util/routes';
import { UserContext } from '../../app/App';
import { UnescoSitesSelect } from './UnescoSitesSelect';
import { type UnescoSite, type Location } from '../../../util/types';
import { LocationSelect } from './LocationSelect';

export const UploadPhoto = (): JSX.Element => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(toUrl(createRoute('photography', {})));
    }
  }, [user]);

  const [image, setImage] = useState<File>();
  const [selectedUnescoSite, setSelectedUnescoSite] = useState<UnescoSite>();
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [siteOrLocation, setSiteOrLocation] = useState<'unesco' | 'location'>();

  const { uploadPhoto } = useUploadPhoto({
    file: image!,
    siteMetadata:
      siteOrLocation === 'unesco' ? selectedUnescoSite! : selectedLocation!,
  });
  return (
    <Box>
      <Container centerContent>
        <Text size="lg" fontWeight="black">
          Upload Photo
        </Text>
        <RadioGroup
          value={siteOrLocation}
          onChange={(e) => {
            setSiteOrLocation(e as 'unesco' | 'location');
          }}
        >
          <Radio value="unesco">Unesco</Radio>
          <Radio value="location">Location</Radio>
        </RadioGroup>
        {siteOrLocation === 'unesco' && (
          <UnescoSitesSelect setSelectedUnescoSite={setSelectedUnescoSite} />
        )}
        {siteOrLocation === 'location' && (
          <LocationSelect
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          />
        )}
        <ImageUpload setImage={setImage} />
        <Button onClick={uploadPhoto}>Submit</Button>
      </Container>
    </Box>
  );
};
