import { isSuccess, success, type Response } from '../../../service/response';
import { type Location } from '../../../util/types';
import { useListLocations as useListLocationsRequest } from '../../../service/requests';

export const useListLocations = (): Response<Location[], Error> => {
  const resp = useListLocationsRequest();

  if (isSuccess(resp)) {
    return success(resp.data);
  }

  return resp;
};
