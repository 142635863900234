import React, {
  type KeyboardEvent,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  Box,
  Button,
  Center,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
} from '@chakra-ui/react';
import { StateView } from '../common/StateView';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../app/App';
import { paths } from '../../util/routes';
import { BreadcrumbGroup } from '../common/breadcrumbs';
import { useListPhotos } from './useListPhotos';
import { IMAGE_URI } from '../../service/constants';
import { useDeletePhoto } from '../../service/requests';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Photo } from '../../util/types';

export const Photography: React.FC = StateView(
  useListPhotos,
  ({ photoResult }) => {
    const { user } = useContext(UserContext);
    const { deletePhoto } = useDeletePhoto();
    const navigate = useNavigate();

    const [modalPhotoId, setModalPhotoId] = useState<string | null>(null);
    const [displayImageBorder, setDisplayImageBorder] =
      useState<boolean>(false);

    const handleOnKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (!modalPhotoId) {
          return;
        }

        const indexOfModalImage = photoResult.findIndex(
          (photo: Photo) => photo.photoId === modalPhotoId,
        );
        switch (event.key) {
          case 'ArrowRight':
            if (indexOfModalImage === photoResult.length - 1) {
              return;
            }
            setModalPhotoId(photoResult[indexOfModalImage + 1].photoId);
            return;
          case 'ArrowLeft':
            if (indexOfModalImage === 0) {
              return;
            }
            setModalPhotoId(photoResult[indexOfModalImage - 1].photoId);
        }
      },
      [modalPhotoId],
    );
    return (
      <Box onKeyDown={handleOnKeyDown}>
        <Modal
          size="6xl"
          onClose={() => {
            setModalPhotoId(null);
          }}
          isOpen={!!modalPhotoId}
        >
          <ModalOverlay />
          <ModalContent>
            <Image
              onClick={() => {
                setDisplayImageBorder((prevState: boolean) => !prevState);
              }}
              style={{
                cursor: 'pointer',
                margin: `${displayImageBorder ? '12px 12px 12px 12px' : '0'}`,
                backgroundColor: 'white',
              }}
              src={`${IMAGE_URI}/${modalPhotoId}`}
            />
          </ModalContent>
        </Modal>
        <BreadcrumbGroup breadcrumbPaths={['home', 'photography']} />
        <Button
          style={{ display: user ? 'block' : 'none' }}
          onClick={() => {
            if (user) {
              navigate(paths.uploadPhoto);
            }
          }}
        >
          Create
        </Button>
        <Center>
          <SimpleGrid
            marginLeft={10}
            marginRight={10}
            columns={3}
            minChildWidth="300px"
            spacing="5px"
            marginBottom={15}
          >
            {photoResult.map(({ photoId }) => {
              return (
                <Box
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setModalPhotoId(photoId);
                  }}
                  key={photoId}
                >
                  <FontAwesomeIcon
                    onClick={() => deletePhoto({ photoId })}
                    size="sm"
                    icon={faTrash}
                    color="#ff575c"
                    style={{
                      cursor: 'pointer',
                      float: 'right',
                      marginTop: '5px',
                      display: user ? 'block' : 'none',
                    }}
                  />
                  <Image src={`${IMAGE_URI}/${photoId}`} />
                </Box>
              );
            })}
          </SimpleGrid>
        </Center>
      </Box>
    );
  },
);
