import * as router from 'react-router';
import { type PhotographyViewType } from './types';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

type NoParam = undefined;

interface RouteType<Name extends string, Params = NoParam> {
  name: Name;
  params: Params;
}

export type Route =
  | RouteType<'home'>
  | RouteType<'about'>
  | RouteType<'projects'>
  | RouteType<'youtubeRandomizerHome'>
  | RouteType<'youtubeRandomizer', { playlistId: string }>
  | RouteType<'cscsHome'>
  | RouteType<'authenticate'>
  | RouteType<'createCscsEntry'>
  | RouteType<'nutrition'>
  | RouteType<'records'>
  | RouteType<'photography', { view?: PhotographyViewType; location?: string }>
  | RouteType<'uploadPhoto'>
  | RouteType<'unesco'>
  | RouteType<'location'>;

export type RouteName = Route['name'];

export const paths: Record<RouteName, string> = {
  home: '/',
  about: '/about',
  projects: '/projects',
  youtubeRandomizerHome: '/youtubeRandomizer',
  youtubeRandomizer: '/youtubeRandomizer/:playlistId',
  cscsHome: '/curatedSongs',
  authenticate: '/authenticate',
  createCscsEntry: '/curatedSongs/create',
  nutrition: '/nutrition',
  records: '/records',
  photography: '/photography/view?/:view?/:location?',
  uploadPhoto: '/photography/create',
  unesco: '/unesco',
  location: '/location',
};

export const pageNames: Record<RouteName, string> = {
  home: 'Casey Smith',
  about: 'About',
  projects: 'Projects',
  youtubeRandomizerHome: 'Youtube Randomizer',
  youtubeRandomizer: 'Player',
  cscsHome: "Casey Smith's Curated Songs",
  authenticate: 'Authenticate',
  createCscsEntry: 'Create Entry',
  nutrition: 'Nutrition Repository',
  records: 'Records',
  photography: 'Photography',
  uploadPhoto: 'Upload Photo',
  unesco: 'UNESCO',
  location: 'Add location',
};

type RouteByName<Name extends RouteName> = Extract<Route, { name: Name }>;

export type RouteParams<Name extends RouteName> = RouteByName<Name>['params'];

export const createRoute = <Name extends RouteName>(
  name: Name,
  ...params: RouteParams<Name> extends NoParam ? [] : [RouteParams<Name>]
): RouteByName<Name> => ({ name, params: params[0] }) as RouteByName<Name>;

export const toUrl = ({ name, params }: Route): string =>
  router.generatePath(paths[name], params);

export const useQueryString = (): URLSearchParams => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
