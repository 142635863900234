import { useListUnescoSites as useListUnescoSitesRequest } from '../../service/requests';
import { isSuccess, success, type Response } from '../../service/response';
import { type UnescoSite } from '../../util/types';

interface Output {
  sites: UnescoSite[];
}

export const useListUnescoSites = (): Response<Output, Error> => {
  const resp = useListUnescoSitesRequest();

  if (isSuccess(resp)) {
    return success({ sites: resp.data });
  }

  return resp;
};
